
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import HeaderUser from "@/components/layouts/HeaderComponents/HeaderUser.vue";
import MobileLayouts from "@/components/Mobile/MobileLayouts.vue";
import Login from "@/views/User/Login.vue";
import { Alert } from "ant-design-vue";

const UserModule = namespace("user");
const cooperationModule = namespace("cooperation");
const CommonModule = namespace("common");

@Component({
  name: "TeamInvited",
  components: { HeaderUser, Login, MobileLayouts, [Alert.name]: Alert },
  filters: {
    //局部过滤器
  },
})
export default class TeamInvited extends Vue {
  public spin: boolean = false;
  public inviteId: string = "";
  public loading: boolean = false;
  public error: boolean = false;
  public cooperationFileDetails: any = {};

  @CommonModule.State("navigator") navigator!: string;
  @UserModule.State("token") token!: string;
  @UserModule.State("name") userName!: string;
  @UserModule.State("companyList") companyList!: any[];
  @UserModule.State("companyId") companyId!: string;
  @UserModule.Action("GET_USERINFO") getUserinfo!: (type?: string) => void;
  @cooperationModule.State("invitedLoginShow") loginShow!: boolean;
  @cooperationModule.Mutation("SET_INVITEDLOGINSHOW") setLoginShow!: (
    value: boolean
  ) => void;

  // 显示企业
  get userCompany(): string {
    let company = this.companyList.find((i) => i.id === this.companyId);
    return this.companyId === "person" ? "个人文档" : company?.companyName;
  }

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("$route.query", { deep: true, immediate: true })
  change(value: any) {
    if (value.key) {
      this.spin = true;
      this.$apis
        .coopFileInfoDetail({ linkUrl: value.key })
        .then((res: any) => {
          if (res.code === "200") {
            this.cooperationFileDetails = res.data;
            this.inviteId = res.data.id;
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.spin = false;
        });
    }
  }
  //ref 引用元素 Ref('ref')
  //@Ref()

  // 登录
  login() {
    this.setLoginShow(true);
  }

  // 切换企业
  changeCompany(company: any) {
    this.$apis
      .companyUpdateDefault({ companyId: company.id })
      .then(async (res: any) => {
        if (res.code === "200") {
          await this.getUserinfo("userinfo");
          this.$message.success(
            `操作成功，个人文档、协作、分享、收藏等数据即将切换到【${
              company.id === "person" ? "个人文档" : company.companyName
            }】`
          );
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      });
  }

  // 加入
  handleJoin() {
    if (this.token) {
      // // 校验是否属于协作文件所属企业成员
      // if (
      //   this.companyList.find(
      //     (i) => i.id === this.cooperationFileDetails.companyId
      //   )
      // ) {
      this.loading = true;
      const herdar = {
        messageType: "coop",
        messageInfo: encodeURI(
          `${this.userName},${this.cooperationFileDetails.coopFileName}.${
            this.cooperationFileDetails.extensionName
          },${
            this.cooperationFileDetails.coopFilePrivilege === "view"
              ? "可查看"
              : "可编辑"
          }`
        ),
        messageTo: this.cooperationFileDetails.userId,
      };
      this.$apis
        .coopUserRelJoinCoop(
          {
            id: this.inviteId,
          },
          herdar
        )
        .then((res: any) => {
          if (res.code === "200") {
            this.$message.success("加入成功");
            // // 加入协作成功之后切换为协作文件所处企业
            // this.$apis
            //   .companyUpdateDefault({
            //     companyId: this.cooperationFileDetails.companyId,
            //   })
            //   .then(async (result: any) => {
            //     if (result.code === "200") {
            //       await this.getUserinfo("userinfo");
            this.$router.push({ path: "/star/cooperation" });
            //   }
            // });
          }
        })
        .finally(() => {
          this.loading = false;
        });
      // } else {
      //   this.$message.warning("不是该企业成员无法加入协作");
      // }
    } else {
      this.$message.warning("账号未登录,请先登录后加入");
      this.setLoginShow(true);
    }
  }

  // 去到首页
  goHome() {
    this.$router.push({ path: "/star" });
  }

  //创建前钩子函数
  created(): void {
    this.getUserinfo();
  }
}
